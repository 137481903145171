import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import PageTop from '../../components/simple/PageTop';

const PublichnayaOferta = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const [items, setItems] = useState();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/publichnaya-oferta`);
            }
        }
    }, [app.lang])

    useEffect(() => {
        if(app.settings){
            let temp = app.settings.find(it => it.type === "public_offer")?.value;
            setItems(temp)
        }
    }, [app.settings])
   
    const item = {
        title: app.localizationsItems?.public_offer
    }

  return (
    <div>
        <PageTop item={item}/>
        {
            items &&
            <section className='blog-section section-b-space'>
                <div className="container-fluid-lg">
                    <div dangerouslySetInnerHTML={{__html: items.description}}></div>
                </div>
            </section>
        }
    </div>
  )
})

export default PublichnayaOferta