import React, { useContext } from 'react'
import CartIcon from './CartIcon'
import Menu from './Menu'
import StickyMenu from './StickyMenu'
import { NavLink } from 'react-router-dom'
import Search from './Search'
import { Dropdown } from 'react-bootstrap'
import MobileFixMenu from '../simple/MobileFixMenu'
import UserIcon from './UserIcon'
import FavoritesIcon from './FavoritesIcon'
import AppServices from '../../services/general/appServices'
import { useState } from 'react'
import MenuMobile from './MenuMobile'
import { useEffect } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import SelectCity from '../simple/SelectCity'
import NotificationTop from '../simple/NotificationTop'

const Header = observer(() => {
    const {app, productCategories} = useContext(Context)

	const [show, setShow] = useState(false);
    const [logo, setLogo] = useState();
    const [sticky, setStiky] = useState();

    const fetchMenu = async () => {
        const res = await AppServices.pages();
        
        if(res.statusCode === 200){
            productCategories.setPages(res.content?.items)
        }
    }
    useEffect(()=> {
        fetchMenu()
    }, [app.lang])

    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo")?.value);
        }
    }, [app.settings])

	const handleShow = () => setShow(true);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const handleChange = (lang) => {
        if(app.lang !== lang.slug){
            app.setLang(lang.slug)
        }
    }

  return (
	<>
        <NotificationTop/>
		<header className={sticky ? "header-2 active" : "header-2"}>
			<div className="top-nav top-header sticky-header sticky-header-3">
				<div className="container-fluid-lg">
					<div className="row">
						<div className="col-12">
							<div className="navbar-top">
								<button className="navbar-toggler d-xl-none d-block p-0 me-3" type="button" onClick={handleShow}>
									<span className="navbar-toggler-icon">
										<i className="iconly-Category icli theme-color"></i>
									</span>
								</button>
								<NavLink to={`/${app.lang}`} className="web-logo nav-logo">
									<img src={logo} className="img-fluid" alt="logo"/>
								</NavLink>
							    <Search/>
								<div className="rightside-menu">
                                    <SelectCity/>
									{app.languages?.length > 1 && <div className="dropdown-dollar">
										<Dropdown id="dropdown-basic">
											<Dropdown.Toggle>
												<span className='lang-color'>{app.lang}</span> <i className="fa-solid fa-angle-down"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu >
												{app.languages.map((lang, index) =>
													<Dropdown.Item key={index} onClick={() => handleChange(lang)}>{lang.title}</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</div>}
									<div className="option-list">
										<ul>
                                            <FavoritesIcon/>
											<CartIcon/>
											<UserIcon/>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid-lg menu-color">
				<div className="row">
					<div className="col-12">
						<div className="main-nav">
							<StickyMenu item={productCategories.pages}/>
							<Menu item={productCategories.pages}/>
							<MenuMobile item={productCategories.pages} show={show} setShow={setShow}/>
						</div>
					</div>
				</div>
			</div>
		</header>
		<MobileFixMenu/>
	</>
  )
})

export default Header