import React, { useState, useEffect } from 'react'
import DeliveryBox from '../simple/DeliveryBox'
import img from "../../images/placeholder.png"

const AboutUs = ({elem}) => {
    const [text, setText] = useState();

    useEffect(() => {
        if (elem) {
            setText(elem.elements);
        }
    }, [elem]);

  return (
	<section className="fresh-vegetable-section section-lg-space">
		<div className="container-fluid-lg">
			<div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
				<div className="col-xl-6 col-12">
					<div className="row g-sm-4 g-2">
						<div className="col-sm-12 col-md-6">
							<div className="fresh-image-2">
								<div>
									<img src={elem.preview_image ? elem.preview_image : img} className="bg-img" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
								</div>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="fresh-image">
								<div>
									<img src={elem.detail_image ? elem.detail_image : img} className="bg-img" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-6 col-12">
					<div className="fresh-contain p-center-left">
						<div>
							<div className="review-title">
								<h4 dangerouslySetInnerHTML={{__html: elem.subtitle}}></h4>
								<h2 dangerouslySetInnerHTML={{__html: elem.title}}></h2>
							</div>

							<div className="delivery-list">
								<p className="text-content"
                                    dangerouslySetInnerHTML={{ __html: elem?.description }}
                                ></p>

								<ul className="delivery-box">
                                    {text?.map((el, index) =>
                                        <li key={index}>
                                            <DeliveryBox el={el}/>
                                        </li>
                                    )}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default AboutUs