import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MAIN_PAGE_ROUTE } from '../../navigation/Constants'
import AppServices from '../../services/general/appServices'
import Widgets from '../simple/Widgets'
import ToTop from '../simple/ToTop'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const Footer = observer(() => {
    const {app, productCategories} = useContext(Context);

	const [logo, setLogo] = useState();
	const [socials, setSocials] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
    const [widgets, setWidgets] = useState();

    const [publicOffer, setPublicOffer] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState();

	useEffect(() => {
        if(app.settings){
			setLogo(app.settings.find(it => it.type === "logo")?.value);
            setSocials(app.settings.find(it => it.type === "socials"));
			setPhone(app.settings.find(it => it.type === "phone"));
			setEmail(app.settings.find(it => it.type === "email"));

            setWidgets(app.settings.find(it => it.type === "widgets")?.value);

            setPublicOffer(app.settings.find(it => it.type === "public_offer")?.value);
            setPrivacyPolicy(app.settings.find(it => it.type === "privacy_policy")?.value);
        }
    }, [app.settings])

  return (
	<footer className="section-t-space footer-section-2">
		<div className="container-fluid-lg">
			<div className="main-footer">
				<div className="row g-md-4 gy-sm-5 gy-2">
					<div className="col-xxl-3 col-xl-4 col-sm-6">
						<NavLink to={`/${app.lang}`} className="foot-logo">
							<img src={logo} className="img-fluid" alt="Logo"/>
						</NavLink>
						<p className="information-text">{app.localizationsItems?.footer_text}</p>

						{socials?.value.find(el => el.value) &&
							<ul className="social-icon">
								{socials.value.map((social, index) => 
									social.value &&
									<li key={index}>
										<NavLink to={social.value} target="_blank">
											<i className={
												social.type === "facebook" ? "fab fa-facebook-f"
												:
												social.type === "twitter" ? "fab fa-twitter"
												:
												social.type === "instagram" ? "fab fa-instagram"
												:
												social.type === "telegram" ? "fab fa-telegram"
												:
												social.type === "whatsapp" ? "fab fa-whatsapp"
                                                :
												social.type === "youtube" ? "fab fa-youtube"
												:
												social.type === "tiktok" ? "fab fa-tiktok"
												:
												"fab fa-facebook-f"
											}></i>
										</NavLink>
									</li>
								)}
							</ul>

						}
					</div>
                    {productCategories.categories?.length >= 1 && 
                        <div className="col-xxl-2 col-xl-4 col-sm-6">
                            <div className="footer-title">
                                <h4>{app.localizationsItems?.catalog}</h4>
                            </div>
                            <ul className="footer-list footer-contact mb-sm-0 mb-3">
                                {productCategories.categories?.map((el, index) =>
                                    <li key={index}>
                                        <NavLink to={`/${app.lang}/catalog/${el.slug}`} className="footer-contain-2">
                                        <i className="fas fa-angle-right"></i>{el.title}</NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                    {productCategories.pages?.length >= 1 && 
                        <div className="col-xxl-2 col-xl-4 col-sm-6">
                            <div className="footer-title">
                                <h4>{app.localizationsItems?.pages}</h4>
                            </div>
                            <ul className="footer-list footer-contact mb-sm-0 mb-3">
                                {productCategories.pages?.map((el, index) =>
                                    el.slug === "articles" ?
                                        <li key={index}>
                                            <NavLink to={`/${app.lang}/${el.slug}`} className="footer-contain-2">
                                            <i className="fas fa-angle-right"></i>{el.title}</NavLink>
                                        </li>
                                        :
                                        el.slug !== "main" &&
                                            <li key={index}>
                                                <NavLink to={`/${app.lang}/page/${el.slug}`} className="footer-contain-2">
                                                <i className="fas fa-angle-right"></i>{el.title}</NavLink>
                                            </li>
                                )}
                                {privacyPolicy && 
                                    <li>
                                        <NavLink to={`/${app.lang}/politika-konfidentsialnosti`} className="footer-contain-2">
                                        <i className="fas fa-angle-right"></i>{app.localizationsItems?.privacy_police}</NavLink>
                                    </li>
                                }
                                {publicOffer && 
                                    <li>
                                        <NavLink to={`/${app.lang}/publichnaya-oferta`} className="footer-contain-2">
                                        <i className="fas fa-angle-right"></i>{app.localizationsItems?.public_offer}</NavLink>
                                    </li>
                                }
                            </ul>
                        </div>
                    }

					<div className="col-xxl-3 col-xl-4 col-sm-6">
						<div className="footer-title">
							<h4>{app.localizationsItems?.information_shop}</h4>
						</div>
						<ul className="footer-address footer-contact">
							{app.localizationsItems?.footer_address &&
								<li>
									<div className="inform-box flex-start-box">
										<i className='fa-solid fa-location-dot mr-3'></i>
										<div dangerouslySetInnerHTML={{__html: app.localizationsItems?.footer_address}}></div>
									</div>
								</li>
                            }
							{app.localizationsItems?.footer_work_time &&
								<li>
									<div className="inform-box flex-start-box">
										<i className='fa-solid fa-business-time mr-3'></i>
										<div dangerouslySetInnerHTML={{__html: app.localizationsItems?.footer_work_time}}></div>
									</div>
								</li>
                            }
							{phone?.value?.map((el, index) =>
								<li key={index}>
									<div className="inform-box">
										<i className="fa fa-phone mr-3"></i>
										<p>
											<a href={`tel:${el}`}>{el}</a>
										</p>
									</div>
								</li>
                            )}
							{email?.value?.map((el, index) =>
								<li key={index}>
									<div className="inform-box">
										<i className="fa fa-mail-bulk mr-3"></i>
										<p>
											<a href={`mailto:${el}`}>{el}</a>
										</p>
									</div>
								</li>
                            )}
						</ul>
					</div>
				</div>
			</div>

			<div className="sub-footer section-small-space">
				<div className="left-footer">
					<p>{app.localizationsItems?.footer_bottom_text}</p>
				</div>
			</div>
		</div>
        <ToTop widgets={widgets}/>  
        {widgets && <Widgets item={widgets}/>}
	</footer>
  )
})

export default Footer